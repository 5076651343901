$(function () {
  'use strict';
  
  if ($('#tax-evasion-report').length > 0) {
    const dir = $('html').attr('dir');

    $('#determine-the-type-of-tax').on('change', function() {
      const value = $(this).val();
      
      if (value.toLowerCase() === 'other') {
        $(this).addClass('hidden');
        $(this).parent().find('input').removeClass('hidden');

        if (dir === 'ltr') {
          $(this).parent().append('<span class="reset-view">Choose again</span>');
          $(this).parent().find('input').attr('placeholder', 'Tax Type')
          
        } else {
          $(this).parent().append('<span class="reset-view">اختر مرة أخرى</span>');
          $(this).parent().find('input').attr('placeholder', 'نوع الضريبة');
        }
      }
    })
    
    $(document).on('click', '.reset-view', function() {
      $(this).parent().find('select').removeClass('hidden');
      $(this).parent().find('input').addClass('hidden');
      $(this).remove();
    })

    $('.entity-number > select').on('change', function() {
      const value = $(this).val();

      if (value === 'passport-number') {
        $('.input-group.entity-qid input').attr('placeholder', dir=='ltr'?'Passport Number':'رقم جواز السفر');
      } else if (value === 'qid-number') {
        $('.input-group.entity-qid input').attr('placeholder', dir=='ltr'?'QID Number':'رقم البطاقة الشخصية');
      }
    })

    $.validator.addMethod("emailPattern", function (value, element) { // new method for email validation
      return this.optional(element) || /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/.test(value);
    });
    jQuery.validator.addMethod("intlTelNumber", function(value, element) {
      return this.optional(element) || $(element).intlTelInput("isValidNumber");
    });

    const messagesError = {
      tax_type: {
        en: 'Please select tax type',
        ar: 'الرجاء تحديد نوع الضريبة'
      },
      tax_id_num: {
        en: 'Please provide tax identification number',
        ar: 'يرجى تقديم رقم التعريف الضريبي'
      },
      entity_name: {
        en: 'Please provide entity name',
        ar: 'يرجى تقديم اسم الجهة'
      },
      entity_address: {
        en: 'Please provide entity address',
        ar: 'يرجى تقديم عنوان الكيان'
      },
      phone: {
        required: {
          en: 'Please provide your number without country code',
          ar: "يرجى تقديم رقمك بدون رمز البلد"
        },
        intlTelNumber: {
          en: 'Please enter a valid phone Number',
          ar: 'يرجى إدخال رقم هاتف صحيح'
        }
      },
      id_number: {
        en: 'Please provide your ID number',
        ar: "رجاء إدخال رقم الهوية الخاصة بك"
      },
      email: {
        en: 'Please enter a valid email address',
        ar: 'رجاء إدخال عنوان بريد إلكتروني صالح'
      },
      details: {
        en: 'Please provide the details',
        ar: 'يرجى تقديم التفاصيل'
      },
      name: {
        en: 'Please provide your name',
        ar: 'رجاء كتابة الاسم الكامل'
      },
      address: {
        en: 'Please provide your address',
        ar: 'يرجى تقديم عنوانك'
      },
      city: {
        en: 'Please provide your city',
        ar: 'يرجى تقديم مدينتك'
      },
      country: {
        en: 'Please provide your country',
        ar: 'يرجى تقديم بلدك'
      },
    }

    /**
     * A function that generates an error message based on the input placeholder.
     *
     * @param {string} inputName - the name used to generate the error message
     * @return {string} the error message generated
     */
    function errorMessage(inputName) {
      let msgEn = 'Please provide ' + $('#tax-reporting [name="' + inputName + '"]').attr('placeholder');
      let msgAr = 'يرجى تقديم ' + $('#tax-reporting [name="' + inputName + '"]').attr('placeholder');
      return dir == 'ltr' ? msgEn : msgAr;
    }

    $('#tax-evasion-report').validate({
      rules: {
        // "determine-the-type-of-tax": "required",
        // "entity-tax-identification-number": "required",
        // "entity-name": "required",
        // "entity-address": "required",
        // "the-details": "required",
        // "name": "required",
        // "tax-identification-number": "required",
        // "commercial-registration-no": "required",
        // "address": "required",
        "phone-to-call": {
          // required: true,
          intlTelNumber: true
        },
        "e-mail": {
          // required: true,
          emailPattern: true
        },
      },
      messages: {
        "determine-the-type-of-tax": dir == 'ltr'? messagesError.tax_type.en : messagesError.tax_type.ar,
        "entity-tax-identification-number": dir == 'ltr'? messagesError.tax_id_num.en : messagesError.tax_id_num.ar,
        "entity-name": dir == 'ltr'? messagesError.entity_name.en : messagesError.entity_name.ar,
        // "entity-address": dir == 'ltr'? messagesError.entity_address.en : messagesError.entity_address.ar,
        "the-details": dir == 'ltr'? messagesError.details.en : messagesError.details.ar,
        "name": dir == 'ltr'? messagesError.name.en : messagesError.name.ar,
        "tax-identification-number": dir == 'ltr'? messagesError.tax_id_num.en : messagesError.tax_id_num.ar,
        "commercial-registration-no": dir == 'ltr'? messagesError.id_number.en : messagesError.id_number.ar,
        "address": dir == 'ltr'? messagesError.address.en : messagesError.address.ar,
        "phone-to-call": {
          required: dir == 'ltr'? messagesError.phone.required.en : messagesError.phone.required.ar,
          intlTelNumber: dir == 'ltr'? messagesError.phone.intlTelNumber.en : messagesError.phone.intlTelNumber.ar
        },
        "e-mail": {
          required: dir == 'ltr'? messagesError.email.en : messagesError.email.ar,
          emailPattern: dir == 'ltr'? messagesError.email.en : messagesError.email.ar
        },
        city: dir == 'ltr'? messagesError.city.en : messagesError.city.ar,
        country: dir == 'ltr'? messagesError.country.en : messagesError.country.ar,
      },
      errorElement: 'em',
    });

    $('#tax-reporting').validate({
      messages: {
        // Informer Individual -->
        "informerInformationIndividualCountry": errorMessage('informerInformationIndividualCountry'),
        "informerInformationIndividualTin": errorMessage('informerInformationIndividualTin'),
        "informerInformationIndividualIssuingAuthority": errorMessage('informerInformationIndividualIssuingAuthority'),
        "informerInformationIndividualName": errorMessage('informerInformationIndividualName'),
        "informerInformationIndividualAddress": errorMessage('informerInformationIndividualAddress'),
        "informerInformationIndividualDob": errorMessage('informerInformationIndividualDob'),
        "informerInformationIndividualAttribute": errorMessage('informerInformationIndividualAttribute'),
        "informerInformationIndividualRelationship": errorMessage('informerInformationIndividualRelationship'),
        // Informer Corporate -->
        "informerInformationCorporateCountry": errorMessage('informerInformationCorporateCountry'),
        "informerInformationCorporateTin": errorMessage('informerInformationCorporateTin'),
        "informerInformationCorporateIssuingAuthority": errorMessage('informerInformationCorporateIssuingAuthority'),
        "informerInformationCorporateEstablishmentNo": errorMessage('informerInformationCorporateEstablishmentNo'),
        "informerInformationCorporateIssuingEstablishmentNo": errorMessage('informerInformationCorporateIssuingEstablishmentNo'),
        "informerInformationCorporateTypeEstablishmentNo": errorMessage('informerInformationCorporateTypeEstablishmentNo'),
        "informerInformationCorporateName": errorMessage('informerInformationCorporateName'),
        "informerInformationCorporateAddress": errorMessage('informerInformationCorporateAddress'),
        "informerInformationCorporateAttribute": errorMessage('informerInformationCorporateAttribute'),
        "informerInformationCorporateRelationship": errorMessage('informerInformationCorporateRelationship'),
        // Reported Party Individual -->
        "reportedPartyIndividualCountry": errorMessage('reportedPartyIndividualCountry'),
        "reportedPartyIndividualTin": errorMessage('reportedPartyIndividualTin'),
        "reportedPartyIndividualIssuingAuthority": errorMessage('reportedPartyIndividualIssuingAuthority'),
        "reportedPartyIndividualName": errorMessage('reportedPartyIndividualName'),
        "reportedPartyIndividualAddress": errorMessage('reportedPartyIndividualAddress'),
        "reportedPartyIndividualDob": errorMessage('reportedPartyIndividualDob'),
        // Reported Party Corporate -->
        "reportedPartyCorporateCountry": errorMessage('reportedPartyCorporateCountry'),
        "reportedPartyCorporateTin": errorMessage('reportedPartyCorporateTin'),
        "reportedPartyCorporateIssuingAuthorityIdNo": errorMessage('reportedPartyCorporateIssuingAuthorityIdNo'),
        "reportedPartyCorporateEstablishmentNo": errorMessage('reportedPartyCorporateEstablishmentNo'),
        "reportedPartyCorporateIssuingEstablishmentNo": errorMessage('reportedPartyCorporateIssuingEstablishmentNo'),
        "reportedPartyCorporateTypeEstablishmentNo": errorMessage('reportedPartyCorporateTypeEstablishmentNo'),
        "reportedPartyCorporateName": errorMessage('reportedPartyCorporateName'),
        "reportedPartyCorporateAddress": errorMessage('reportedPartyCorporateAddress'),
        // Brokers Individual -->
        "brokersIndividualDob": errorMessage('brokersIndividualDob'),
        "brokersIndividualTin": errorMessage('brokersIndividualTin'),
        "brokersIndividualIssuingAuthorityTin": errorMessage('brokersIndividualIssuingAuthorityTin'),
        "brokersIndividualName": errorMessage('brokersIndividualName'),
        "brokersIndividualAddress": errorMessage('brokersIndividualAddress'),
        // Brokers Corporate -->
        "brokersCorporateDob": errorMessage('brokersCorporateDob'),
        "brokersCorporateTin": errorMessage('brokersCorporateTin'),
        "brokersCorporateIssuingIdNo": errorMessage('brokersCorporateIssuingIdNo'),
        "brokersCorporateEstablishmentNo": errorMessage('brokersCorporateEstablishmentNo'),
        "brokersCorporateIssuingEstablishmentNo": errorMessage('brokersCorporateIssuingEstablishmentNo'),
        "brokersCorporateTypeEstablishmentNo": errorMessage('brokersCorporateTypeEstablishmentNo'),
        "brokersCorporateName": errorMessage('brokersCorporateName'),
        "brokersCorporateAddress": errorMessage('brokersCorporateAddress'),
        // Structure -->
        "structureDobReporting": errorMessage('structureDobReporting'),
        "structureReason": errorMessage('structureReason'),
        "structureType": errorMessage('structureType'),
        "structureOtherInformation": errorMessage('structureOtherInformation'),
        // Structure Individual -->
        "structureIndividualCountry": errorMessage('structureIndividualCountry'),
        "structureIndividualTin": errorMessage('structureIndividualTin'),
        "structureIndividualIssuingAuthorityTin": errorMessage('structureIndividualIssuingAuthorityTin'),
        "structureindividualName": errorMessage('structureindividualName'),
        "structureindividualAddress": errorMessage('structureindividualAddress'),
        "structureIndividualDob": errorMessage('structureIndividualDob'),
        "structureIndividualOwnership": errorMessage('structureIndividualOwnership'),
        "structureIndividualInvestedAmount": errorMessage('structureIndividualInvestedAmount'),
        "structureIndividualCurrency": errorMessage('structureIndividualCurrency'),
        "structureIndividualDescription": errorMessage('structureIndividualDescription'),
        "structureIndividualCompetent": errorMessage('structureIndividualCompetent'),
        "structureIndividualSummary": errorMessage('structureIndividualSummary'),
        // Structure Corporate -->
        "structureCorporateCountry": errorMessage('structureCorporateCountry'),
        "structureCorporateTin": errorMessage('structureCorporateTin'),
        "structureCorporateIssuingAuthorityTin": errorMessage('structureCorporateIssuingAuthorityTin'),
        "structureCorporateEstablishmentNo": errorMessage('structureCorporateEstablishmentNo'),
        "structureCorporateIssuingEstablishmentNo": errorMessage('structureCorporateIssuingEstablishmentNo'),
        "structureCorporateTypeEstablishmentNo": errorMessage('structureCorporateTypeEstablishmentNo'),
        "structureCorporateName": errorMessage('structureCorporateName'),
        "structureCorporateAddress": errorMessage('structureCorporateAddress'),
        "structureCorporateOwnership": errorMessage('structureCorporateOwnership'),
        "structureCorporateInvestedAmount": errorMessage('structureCorporateInvestedAmount'),
        "structureCorporateCurrency": errorMessage('structureCorporateCurrency'),
        "structureCorporateDescription": errorMessage('structureCorporateDescription'),
        "structureCorporateCompetent": errorMessage('structureCorporateCompetent'),
        "structureCorporateSummary": errorMessage('structureCorporateSummary'),
      },
      errorElement: 'em',
    })
  }
})