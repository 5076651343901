$(function () {
  'use strict';

  if ($('.search-body-screen').length > 0) {
    const dir = $('html').attr('dir');

    $.validator.addMethod("englishRegex", function (value, element) {
      return this.optional(element) || /^[a-zA-Z\s!"#$%&'()*+,\-./:;<=>?@[\\\]^_`{|}~]*$/
        .test(value);
    });
    $.validator.addMethod("arabicRegex", function (value, element) {
      return this.optional(element) || /[\u0600-\u06FF\u0750-\u077F\u08A0-\u08FF\uFB50-\uFDFF\uFE70-\uFEFF!"#$%&'()*+,\-./:;<=>?@[\\\]^_`{|}~]/g
        .test(value);
    });
    const messagesError = {
      search: {
        required: {
          en: 'Please fill this field',
          ar: 'يرجى ملء هذا الحقل',
        }
      }
    }
    $('form.search-input').validate({
      rules: {
        search: {
          englishRegex: dir == 'ltr' ? true : false,
          arabicRegex: dir == 'rtl' ? true : false,
        }
      },
      messages: {
        search: {
          required: dir == 'ltr' ? messagesError.search.required.en : messagesError.search.required.ar,
          englishRegex: 'Please enter English characters only',
          arabicRegex: 'الرجاء إدخال الحروف العربية فقط'
        }
      },
      errorElement: 'em',
      submitHandler: function (e) {
        console.log('submitted!');
      },
    })
  }
})