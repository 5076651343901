$(function () {
  'use strict';
  if ($('.calendar-ui-box').length > 0) {
    const dir = $('html').attr('dir');
    
    // activities show more on text
    function eventsTextCut() {
      //This limit you can set after how much characters you want to show Read More.
      var carLmt = 300;
      if (dir === 'ltr') {
        // Text to show when text is collapsed
        var readMoreTxt = " ...read more";
        // Text to show when text is expanded
        var readLessTxt = " read less";
      } else {
        var readMoreTxt = " ... إظهار المزيد";
        var readLessTxt = "إظهار أقل ";
      }

      //Traverse all selectors with this class and manipulate HTML part to show Read More
      $(".show-read-more").each(function () {
        if ($(this).find(".first-section").length)
          return;

        var allstr = $(this).text();
        if (allstr.length > carLmt) {
          var firstSet = allstr.substring(0, carLmt);
          var secdHalf = allstr.substring(carLmt, allstr.length);
          var strtoadd = firstSet + "<span class='second-section'>" + secdHalf + "</span><span class='read-more'  title='Click to Show More'>" + readMoreTxt + "</span><span class='read-less' title='Click to Show Less'>" + readLessTxt + "</span>";
          $(this).html(strtoadd);
        }
      });
    }
    //Read More and Read Less Click Event binding
    $(document).on("click", ".read-more,.read-less", function () {
      $(this).closest(".show-read-more").toggleClass("show-less-content show-more-content");
    });
    // activities show more on text


    // start dynamic calendar
    // getting new date, current year and month
    let date = new Date(),
      currYear = date.getFullYear(),
      currMonth = date.getMonth();

    // full name of all months in array
    const months = ["January", "February", "March", "April", "May", "June", "July",
      "August", "September", "October", "November", "December"];
    const monthsAr = ["يناير", "فبراير", "مارس", "أبريل", "مايو", "يونيو", "يوليو",
      "أغسطس", "سبتمبر", "أكتوبر", "نوفمبر", "ديسمبر"];

    const daysTag = document.querySelector(".calendar-ui-box .days"),
      currentDate = document.querySelector(".calendar-ui-box .current-date"),
      prevNextIcon = document.querySelectorAll(".calendar-ui-box .current-date-picker span");

    const renderCalendar = () => {
      let firstDayofMonth = new Date(currYear, currMonth, 1).getDay(), // getting first day of month
        lastDateofMonth = new Date(currYear, currMonth + 1, 0).getDate(), // getting last date of month
        lastDayofMonth = new Date(currYear, currMonth, lastDateofMonth).getDay(), // getting last day of month
        lastDateofLastMonth = new Date(currYear, currMonth, 0).getDate(); // getting last date of previous month
      let liTag = "";

      for (let i = firstDayofMonth; i > 0; i--) { // creating li of previous month last days
        liTag += `<li class="inactive">${lastDateofLastMonth - i + 1}</li>`;
      }

      for (let i = 1; i <= lastDateofMonth; i++) { // creating li of all days of current month
        // adding active class to li if the current day, month, and year matched
        let isToday = i === date.getDate() && currMonth === new Date().getMonth()
          && currYear === new Date().getFullYear() ? "day active" : "day";
        let monthYear = dir == 'rtl' ? '-' + monthsAr[currMonth] + '-' + currYear : '-' + months[currMonth].substring(0, 3) + '-' + currYear;
        liTag += `<li lang="en"><button class="${isToday}"><span>${i}</span><span class="visually-hidden">${monthYear}</span></button></li>`;
      }

      if (dir === 'rtl') {
        for (let i = lastDayofMonth; i < 6; i++) { // creating li of next month first days
          liTag += `<li class="inactive">${i - lastDayofMonth + 1}</li>`
        }
        currentDate.innerText = `${monthsAr[currMonth]} ${currYear}`; // passing current mon and yr as currentDate text
        daysTag.innerHTML = liTag;
      } else {
        for (let i = lastDayofMonth; i < 6; i++) { // creating li of next month first days
          liTag += `<li class="inactive">${i - lastDayofMonth + 1}</li>`
        }
        currentDate.innerText = `${months[currMonth]} ${currYear}`; // passing current mon and yr as currentDate text
        daysTag.innerHTML = liTag;
      }
    }
    renderCalendar();

    var eventsUrl = $("#event-list").val();
    $.ajax({
      // url: './assets/apis/events-json.json',
      url: eventsUrl,
      type: 'get',
      success: function (evetsData) {
        evetsData = $.parseJSON(evetsData);
        var eventsJson = evetsData.root.assets;

        prevNextIcon.forEach(icon => { // getting prev and next icons
          icon.addEventListener("click", () => { // adding click event on both icons
            // if clicked icon is previous icon then decrement current month by 1 else increment it by 1
            currMonth = icon.id === "prev" ? currMonth - 1 : currMonth + 1;

            if (currMonth < 0 || currMonth > 11) { // if current month is less than 0 or greater than 11
              // creating a new date of current year & month and pass it as date value
              date = new Date(currYear, currMonth);
              currYear = date.getFullYear(); // updating current year with new date year
              currMonth = date.getMonth(); // updating current month with new date month
            } else {
              date = new Date(); // pass the current date as date value
            }
            renderCalendar(); // calling renderCalendar function

            var eventsData = eventsJson.document;
            var dateArray = [];

            for (let i = 0; i < eventsData.length; i++) {
              var itemDate = eventsData[i].content.root.information.date;
              dateArray.push(itemDate)
            }

            $('.calendar-ui-box .wrapper .calendar .days .day').each(function () {
              // highlight day if event accuring on this day
              var activeDay = $(this).text();
              if (dateArray.includes(activeDay)) {
                $(this).addClass('hover');
              }
            })
          });
        });

        if ($('.homepage-body-screen').length > 0) {
          // var sideMonth = $('.side-calendar .full-date .month');
          // var sideDay = $('.side-calendar .full-date .day');
          // var sideYear = $('.side-calendar .full-date .year');
          // if (dir == 'rtl') {
          //   sideMonth.text(monthsAr[currMonth]);
          // } else {
          //   sideMonth.text(months[currMonth]);
          // }
          // sideDay.text(date.getDate());
          // sideYear.text(currYear);

          // $('.side-calendar .pick-day').on('click', function () {
          //   $('.side-calendar .calendar-ui-box').removeClass('hidden');
          // })
          // $('.side-calendar .calendar-ui-box .close-btn').on('click', function () {
          //   $('.side-calendar .calendar-ui-box').addClass('hidden');
          // })

          // var activeDay = $('.side-calendar .full-date .day').text();

          var eventsData = eventsJson.document;
          var dateArray = [];
          // console.log(eventsData);
          $('.tax-calendar-content .events-content .text').remove();
          $('.no-events-box').removeClass('active');

          eventsData.map((event) => {
            var itemDate = event.content.root.information.date;
            dateArray.push(itemDate)
            if (activeDay == itemDate) {
              $('.events-content').append(`
                <div class="text">
                  <div class="bold-text">
                    ${event.content.root.event_detail.title}
                  </div>
                  <p>
                    ${event.content.root.event_detail.description}
                  </p>
                </div>
              `)
              return
            }
          })

          $('.calendar-ui-box .wrapper .calendar .days .day').each(function () {
            // highlight day if event accuring on this day
            var activeDay = $(this).text();
            if (dateArray.includes(activeDay)) {
              $(this).addClass('hover');
            }
          })

          if (!dateArray.includes(activeDay)) {
            $('.no-events-box').addClass('active');
            $('.tax-calendar-content .events-content').addClass('hidden');
          }


          var readMoreUrl = $('.tax-calendar.container .go-to-link > a').attr('href');

          $(document).on('click', '.calendar-ui-box .wrapper .calendar .days .day', function () {
            $('.calendar-ui-box .wrapper .calendar .days .day').removeClass('active');
            $(this).addClass('active');
            $('.side-calendar .calendar-ui-box').addClass('hidden');
            var activeDay = $.trim($(this).text());
            var monthParam = '';
            var dayParam = '';
            var yearParam = '';

            // if (dir == 'rtl') {
            //   sideMonth.text(monthsAr[currMonth]);
            // } else {
            //   sideMonth.text(months[currMonth]);
            // }
            // sideDay.text($.trim($(this).find('span:first-child').text()));
            // sideYear.text(currYear);

            var eventsData = eventsJson.document;
            var dateArray = [];

            if (dir == 'rtl') {
              monthParam = monthsAr[currMonth];
            } else {
              monthParam = months[currMonth];
            }
            dayParam = $.trim($(this).find('span:first-child').text());
            yearParam = currYear;

            $('.tax-calendar-content .events-content .text').remove();
            $('.no-events-box').removeClass('active');

            eventsData.map((event) => {
              var itemDate = event.content.root.information.date;
              dateArray.push(itemDate)
              if (activeDay == itemDate) {
                // passing date params to "show more" button attribute (href);
                $('.tax-calendar.container .go-to-link > a').attr(
                  'href', readMoreUrl + '?date=' + activeDay
                  + '&m=' + monthParam + '&d=' + dayParam + '&y=' + yearParam
                );
                // passing date params to "show more" button attribute (href);

                $('.tax-calendar-content .events-content .text').remove();
                $('.tax-calendar-content .events-content').removeClass('hidden');
                $('.events-content').append(`
                      <div class="text">
                        <div class="bold-text">
                          ${event.content.root.event_detail.title}
                        </div>
                        <p>
                          ${event.content.root.event_detail.description}
                        </p>
                      </div>
                    `)
              }
            })

            if (!dateArray.includes(activeDay)) {
              $('.no-events-box').addClass('active');
              $('.tax-calendar-content .events-content').addClass('hidden');
              $('.tax-calendar.container .go-to-link > a').attr('href', readMoreUrl);
            }
          })
        }

        if ($('.events-body-screen').length > 0) { // events on page load

          var urlParams = new URLSearchParams(window.location.search);
          var eventDateParam = urlParams.get('date');
          var eventMonthParam = urlParams.get('m');
          // var eventDayParam = urlParams.get('d');
          var eventYearParam = urlParams.get('y');

          if (urlParams.size > 0) {
            if (dir === 'ltr') {
              currMonth = months.indexOf(eventMonthParam);
            } else {
              currMonth = monthsAr.indexOf(eventMonthParam);
            }
            currYear = eventYearParam;
            renderCalendar();
            $('.calendar-ui-box .wrapper .calendar .days .day').each(function () {
              if ($(this).text() === eventDateParam)
                $(this).addClass('active');
            })
            // console.log(eventMonthParam, eventDayParam, eventYearParam);
          }

          var activeDay = $('.calendar-ui-box .wrapper .calendar .days .day.active').text();
          var eventsData = eventsJson.document;
          var dateArray = [];
          // console.log(eventsData);
          $('.events-boxes .news-box').remove();
          $('.no-events-box').removeClass('active');

          eventsData.map((event) => {
            var itemDate = event.content.root.information.date;
            dateArray.push(itemDate)
            if (eventDateParam != '') {
              activeDay = eventDateParam;
              if (activeDay == itemDate) {
                $('.events-wrapper').append(`
                      <div class="news-box">
                        <div class="news-img">
                          <img
                            src="${event.content.root.information.image}"
                            alt="${event.content.root.information.alt_text}"
                          />
                        </div>
                        <div class="news-box-details">
                          <div class="date-and-location">
                            <div class="date">${itemDate}</div>
                            <div class="location">${event.content.root.information.location}</div>
                          </div>
                          <div class="title">
                            ${event.content.root.event_detail.title}
                          </div>
                          <p class="show-read-more show-less-content">
                            ${event.content.root.event_detail.description}
                          </p>
                        </div>
                      </div>
                    `)
              }
            } else {
              if (activeDay == itemDate) {
                $('.events-wrapper').append(`
                      <div class="news-box">
                        <div class="news-img">
                          <img
                            src="${event.content.root.information.image}"
                            alt="${event.content.root.information.alt_text}"
                          />
                        </div>
                        <div class="news-box-details">
                          <div class="date-and-location">
                            <div class="date">${itemDate}</div>
                            <div class="location">${event.content.root.information.location}</div>
                          </div>
                          <div class="title">
                            ${event.content.root.event_detail.title}
                          </div>
                          <p class="show-read-more show-less-content">
                            ${event.content.root.event_detail.description}
                          </p>
                        </div>
                      </div>
                    `)
              }
            }
          })

          eventsTextCut();

          $('.calendar-ui-box .wrapper .calendar .days .day').each(function () {
            // highlight day if event accuring on this day
            // var activeDay = $(this).text();
            var activeDay = $.trim($(this).text());
            if (dateArray.includes(activeDay)) {
              $(this).addClass('hover');
            }
          })

          if (!dateArray.includes(activeDay)) {
            $('.no-events-box').addClass('active');
          }

          $(document).on('click', '.calendar-ui-box .wrapper .calendar .days .day', function () {
            $('.calendar-ui-box .wrapper .calendar .days .day').removeClass('active');
            $(this).addClass('active');
            var activeDay = $.trim($(this).text());

            var eventsData = eventsJson.document;
            var dateArray = [];
            $('.events-boxes .news-box').remove();
            $('.no-events-box').removeClass('active');

            eventsData.map((event) => {
              var itemDate = event.content.root.information.date;
              dateArray.push(itemDate)
              if (activeDay == itemDate) {
                $('.events-wrapper').append(`
                        <div class="news-box">
                          <div class="news-img">
                            <img
                              src="${event.content.root.information.image}"
                              alt="${event.content.root.information.alt_text}"
                            />
                          </div>
                          <div class="news-box-details">
                            <div class="date-and-location">
                              <div class="date">${itemDate}</div>
                              <div class="location">${event.content.root.information.location}</div>
                            </div>
                            <div class="title">
                              ${event.content.root.event_detail.title}
                            </div>
                            <p class="show-read-more show-less-content">
                              ${event.content.root.event_detail.description}
                            </p>
                          </div>
                        </div>
                    `)
              }
            })

            eventsTextCut();

            if (!dateArray.includes(activeDay)) {
              $('.no-events-box').addClass('active');
            }
          })
        }
      }
    })
  }
  // end dynamic calendar
})