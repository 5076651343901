/*global $, JQuery, alert, console*/
'use strict';
$(function () {

  const dir = $('html').attr('dir');

  // accessibilty improvements
  setTimeout(() => {
    $('.g-recaptcha-response').parent().append(`
        <label for="g-recaptcha-response-100000" class="visually-hidden">recaptcha field</label>`);
  }, 500);

  $('.report-body-screen form input').removeAttr('aria-describedby');
  $('.report-body-screen form input').removeAttr('aria-label');
  // accessibilty improvements

  /// resize font
  var $affectedElements = $("p, h1, h2, h3, h4, h5, h6, div, a, button"); // Can be extended, ex. $("div, p, span.someClass")
  var increaseCount = 0
  var decreaseCount = 0
  // Storing the original size in a data attribute so size can be reset
  $affectedElements.each(function () {
    var $this = $(this);
    $this.data("orig-size", $this.css("font-size"));
  });

  $(".text-plus").click(function () {
    if (increaseCount < 2) {
      changeFontSize(1);
      increaseCount++
      decreaseCount--
    }
  })

  $(".text-minus").click(function () {
    if (decreaseCount < 2) {
      changeFontSize(-1);
      decreaseCount++
      increaseCount--
    }
  })

  $(".text-default").click(function () {
    increaseCount = 0
    decreaseCount = 0
    $affectedElements.each(function () {
      var $this = $(this);
      $this.css("font-size", $this.data("orig-size"));
    });
  })

  function changeFontSize(direction) {
    $affectedElements.each(function () {
      var $this = $(this);
      $this.css("font-size", parseInt($this.css("font-size")) + direction);
    });
  }
  // resize font

  // $(document).on('click', '.overlay', function() {
  //   $('.overlay').removeClass('active');
  // })

  // scroll functions
  function stopScroll() {
    $('body').css('overflow', 'hidden');
  };

  function normalScroll() {
    $('body').css('overflow', 'unset');
  };
  // scroll functions

  $('.dhareeba-header .dhareeba-nav-list .search-btn-sticky').on('click', function () {
    $('html, body').animate({ scrollTop: 0 }, 100);
  })

  // start faq page slick-slider
  function faqSlick() {
    $('.main-faq-content .slick').slick({
      infinite: false,
      swipeToSlide: true,
      draggable: true,
      rtl: dir == 'rtl'? true : false,
      ltr: dir == 'ltr'? true : false,
      variableWidth: true,
      prevArrow: $('.main-faq-content .right-btn'),
      nextArrow: $('.main-faq-content .left-btn'),
    });
  }

  if ($('.sections-links a.section-link').length <= 4) {
    $('.main-faq-content .view-all-bar').addClass('hidden');
  }

  if ($('.sections-links a.section-link').length > 4 && $('.faq-body-screen').length) {
    faqSlick();
    $('.main-faq-content .view-all-bar').removeClass('hidden');
  } else if ($(window).width() < 992 && $('.faq-body-screen').length) {
    $('.main-faq-content .view-all-bar').removeClass('hidden');
    faqSlick();
  } else if ($(window).width() < 1200 && $('.sections-links a.section-link').length <= 4) {
    $('.main-faq-content .view-all-bar').removeClass('hidden');
    faqSlick();
  }
  // faqSlick();

  $('.main-faq-content .view-all-bar').on('click', function () {
    $('.main-faq-content .slick').slick('unslick');
    $('.main-faq-content .view-all-bar').addClass('hidden');
    $('.main-faq-content .section-link.minimize').css('display', 'flex').appendTo('.main-faq-content .sections-links');
    $('.main-faq-content .carousel-field').addClass('no-slick');
  })
  $(document).on('click', '.main-faq-content .section-link.minimize', function () {
    $('.main-faq-content .view-all-bar').removeClass('hidden');
    faqSlick();
    $('.main-faq-content .sections-links .section-link.minimize').css('display', 'none');
  })
  // end faq page slick-slider

  // start taxes slick-slider
  if ($(window).width() >= 992 && $('.main-taxes-content .slick .taxes-links').length > 4) {
    $('.main-taxes-content .slick').slick({
      autoplay: false,
      arrows: false,
      infinite: false,
      // slidesToShow: 5.88,
      // slidesPerRow: 1,
      // slidesToScroll: 1,
      variableWidth: true,
      draggable: true,
      rtl: dir == 'rtl'? true : false,
      ltr: dir == 'ltr'? true : false,
      // responsive: [
      //   {
      //     breakpoint: 1400,
      //     settings: {
      //       slidesToShow: 4.7,
      //     },
      //   },
      //   {
      //     breakpoint: 1250,
      //     settings: {
      //       slidesToShow: 4,
      //     }
      //   },
      //   {
      //     breakpoint: 1000,
      //     settings: {
      //       slidesToShow: 3.5,
      //     }
      //   },
      // ],
    });
  }
  // end taxes slick-slider

  /* start pagination active change */
  $('.pagination-item').on('click', function () {
    $('.pagination-item').removeClass('active');
    $(this).addClass('active');
  })
  /* end pagination active change */

  // start dropdown text replace
  // $('.nav-content').on('click', function () {
  //   var content = $('.nav-content.active').text();
  //   $('.dropdown-content').text(content);
  // })
  // end dropdown text replace

  // appointment add attendees
  $('.add-attendees .add-attendees-btn').on('click', function () {
    const attendeesCount = $('.attendees').length;
    // console.log(attendeesCount);
    if ($('.attendees:not(.active)').length == attendeesCount) {
      $('.attendees:first-child').addClass('active').find('input').removeAttr('hidden');
    } else {
      $('.attendees:not(.active)').first().addClass('active').find('input').removeAttr('hidden');
    }
  })

  $('.add-attendees .remove-btn').on('click', function () {
    $(this).parent().parent().removeClass('active').find('input').attr('hidden', 'hidden');
  })

  $('.res-reason .form-select').on('change', function () {
    if ($(this).val() == 'other') {
      $(this).addClass('hidden');
      $('.res-reason input').removeClass('hidden');
    }
  })
  // appointment add attendees

  // START copy URL to clipboard
  function copyToClipboard(element, eleUrl) {
    var $temp = $("<input>");
    if (dir == 'ltr') {
      var copyDiv = $("<div class='copy-url'>Copied to clipboard</div>");
    } else {
      var copyDiv = $("<div class='copy-url'>نسخ إلى الحافظة</div>");
    }

    var $url = eleUrl;
    element.append(copyDiv);

    $("body").append($temp);
    $temp.val($url).select();
    document.execCommand("copy");
    $temp.remove();
    setTimeout(function () {
      copyDiv.remove();
    }, 2000)
  }
  $('.feedback-last-update-and-share .share').on('click', function (e) {
    e.preventDefault();
    copyToClipboard($(this), $(location).attr('href'));
  });

  // latest news share btn
  $(document).on('click', '.continue-and-share .share', function (e) {
    e.preventDefault();
    copyToClipboard($(this), $(this).siblings('.continue')[0].href);
  })
  $(document).on('click', '.desc-and-share .share', function (e) {
    e.preventDefault();
    copyToClipboard($(this), $(this).parent().find('p > a')[0].href);
  })
  // latest news share btn
  // END copy URL to clipboard

  // start calendar show in another container (events page)
  if ($(window).width() <= 991.98 && $('.events-body-screen').length > 0) {
    $('.events-body-screen .calendar-ui-box').appendTo('.media-calendar')
  } else {
    $('.events-body-screen .media-calendar .calendar-ui-box').remove();
  }
  // end calendar show in another container (events page)

  // start nav-bar actions
  $('.dhareeba-header .dhareeba-nav-list .expandable').on({
    // mouseover: function() {
    //   var targetExpand = $(this).find('.expandable').attr('data-target_category');
    //   $('.dhareeba-header .sub-menu[data-category]').hide();
    //   $('.dhareeba-header .expandable').removeClass('active')
    //   $('.dhareeba-header .sub-menu[data-category=' + targetExpand + ']').show();
    //   $(this).find('.expandable').addClass('active');
    // },
    // mouseleave: function() {
    //   $('.dhareeba-header .sub-menu[data-category]').hide();
    //   $('.dhareeba-header .expandable').removeClass('active')
    // },
    click: function() {
      var targetExpand = $(this).attr('data-target_category');
      if ($(this).hasClass('active')) {
        $('.dhareeba-header .sub-menu[data-category]').hide();
        $('.dhareeba-header .expandable').removeClass('active');
      } else {
        $('.dhareeba-header .sub-menu[data-category]').hide();
        $('.dhareeba-header .expandable').removeClass('active')
        $('.dhareeba-header .sub-menu[data-category=' + targetExpand + ']').show();
        $(this).addClass('active');
      }
    }
  });

  // start mobile-nav
  $('.mobile-nav-list .main-list .mobile-exp').on('click', function () {
    var targetMobile = $(this).attr('data-target_category');
    $('.mobile-nav-list .main-list').hide()
    $('.mobile-nav-list > ul[data-category=' + targetMobile + ']').show();
  });
  $('.mobile-nav-list .back-to-main').on('click', function () {
    $('.mobile-nav-list > ul').hide();
    $('.mobile-nav-list .main-list').show();
  })
  $('.dhareeba-nav-bar .menu-btn').on('click', function () {
    $('.overlay').toggleClass('active');
    $('.mobile-nav').slideToggle(300);
    $('.mobile-nav-list > ul').hide();
    $('.mobile-nav-list .main-list').show();
  })

  // start mobile search
  $('.dhareeba-nav-bar .search-btn').on('click', function () {
    $('.overlay').addClass('active');
    $('.dhareeba-nav-bar .menu-btn').css('display', 'none');
    $('.mobile-nav').hide();
    $('.dhareeba-nav-bar .close-search-btn').css('display', 'block');
    $('.dhareeba-header .search-screen').css('display', 'block');
  })
  $('.dhareeba-nav-bar .close-search-btn').on('click', function () {
    $('.overlay').removeClass('active');
    $('.dhareeba-nav-bar .menu-btn').css('display', 'block');
    $('.dhareeba-nav-bar .close-search-btn').css('display', 'none');
    $('.dhareeba-header .search-screen').css('display', 'none');
  })
  // end mobile search
  // end nav-bar actions

  // start footer slick-slider
  $('.dhareeba-footer .related-links .slick').slick({
    autoplay: true,
    arrows: false,
    infinite: true,
    autoplaySpeed: 5000,
    // slidesToShow: 7,
    slidesToScroll: 1,
    swipeToSlide: true,
    variableWidth: true,
    rtl: dir == 'rtl'? true : false,
    ltr: dir == 'ltr'? true : false
  });
  // function footerSlickInit() {
  // }
  // var slickContent = $('.slick .related-links-img').length;
  // var width = $(window).width();
  // if (width > 992) {
  //   if (slickContent > 7) {
  //     footerSlickInit();
  //   }
  // } else if (width < 992 && width > 768) {
  //   if (slickContent > 4) {
  //     footerSlickInit();
  //   }
  // } else {
  //   footerSlickInit();
  // }
  // end footer slick-slider

  let eServicesShowMoreBtn = $('.e-services-body-screen .more-services .text').text();
  let eServicesShowMoreBtnImg = $('.e-services-body-screen .more-services .more-img img').attr('src');
  $(document).on('click', '.e-services-body-screen .taxes-links-boxes .more-services', function(e) {
    e.preventDefault();
    if (!$(this).hasClass('show-less')) {
      $(this).addClass('show-less');
      $('.taxes-links-boxes .taxes-link:nth-child(+n+8)').addClass('d-flex');
      if (dir == 'ltr') {
        $('.more-services .text').text('Show less');
      } else {
        $('.more-services .text').text('إظهار أقل');
      }
      $('.e-services-body-screen .more-services .more-img').addClass('minimize');
      $('.e-services-body-screen .more-services .more-img img').attr('src', '/assets/images/svg/minimize-btn-white.svg');
    } else {
      $(this).removeClass('show-less');
      $('.taxes-links-boxes .taxes-link:nth-child(+n+8)').removeClass('d-flex');
      $('.more-services .text').text(eServicesShowMoreBtn);
      $('.e-services-body-screen .more-services .more-img').removeClass('minimize');
      $('.e-services-body-screen .more-services .more-img img').attr('src', eServicesShowMoreBtnImg);
    }
  })

  // start service-name tabs slider
  if ($(window).width() <= 1300 && $('.tabs-bar .nav-tabs').length > 0) {
    $('.tabs-bar .nav-tabs').slick({
      autoplay: false,
      arrows: false,
      infinite: false,
      slidesToShow: 2,
      variableWidth: true,
      swipeToSlide: true,
      rtl: dir == 'rtl'? true : false,
      ltr: dir == 'ltr'? true : false,
    })
    $('.tabs-bar .nav-tabs .nav-link').on('click', function () {  // fix tabs active state in mobile
      $('.tabs-bar .nav-tabs .nav-link').removeClass('active');
      $(this).addClass('active');
    })
  }
  // end service-name tabs slider

  // start e-services tabs
  $('.e-services-filters .filter-boxes .filter-box').on('click', function () {
    $('.filter-box').removeClass('active');
    $(this).addClass('active')
    var target_category = $(this).attr('data-target_category');
    $('.filter-box-expand').removeClass('active');
    $('.filter-box-expand[data-category=' + target_category + ']').addClass('active');

    // make sub category active on click on main category
    $('.filter-box-expand .filter-type').removeClass('active');
    $('.filter-box-expand[data-category=' + target_category + ']').children('.filter-type:first-child').addClass('active');

    // fetch data for sub category
    var category = $('.filter-box-expand.active').attr('data-category');
    var catValue = $('.filter-type.active').attr('data-subCategory');
    if (!$(this).hasClass('search-content')) customAjax(category, catValue, "", "");
  });

  $('.filter-box-expand .filter-type').on('click', function () {
    $('.filter-box-expand .filter-type').removeClass('active');
    $(this).addClass('active');

    var category = $('.filter-box-expand.active').attr('data-category');
    var catValue = $(this).attr('data-subCategory');
    customAjax(category, catValue, "", "");
  })

  $('#service-search').keypress(function (e) {
    var key = e.which;
    if (key == 13) {
      customAjax("", "","" ,$('#service-search').val())
    }
  });

  $('.services-search-btn').click(function (e) {
    e.preventDefault();
    if ($('#service-search').val() !== '') customAjax("", "","" ,$('#service-search').val());
  });

  function customAjax(category, catValue, searchText, titleSearchText) {
    var url = $("#service-ajax").val();
    var paramList = {};

    if (catValue != "" && catValue != undefined) {
      paramList[category] = catValue;
    }

    if (searchText != "" && searchText != undefined) {
      paramList["searchString"] = searchText;
    }
    if (titleSearchText != "" && titleSearchText != undefined) {
      paramList["titleSearch"] = titleSearchText;
    }

    $.ajax({
      url: url,
      data: paramList,
      type: "GET",
      dataType: "html",
      beforeSend: function () {
        $("#list-of-services").prepend('<div class="loader-wrapper inside"><div class="loader"></div></div>');
        $('.loader-wrapper').parent().css('position', 'relative');
      },
      success: function (data) {
        var result = $('<div />').append(data).find("#list-of-services").html();
        $("#list-of-services").html(result);
        $("body div.loader-wrapper").remove();
        setTimeout(function () {
            $(".loader-wrapper").remove();
        }, 2000)
      }
    });
  }

  if ($(window).width() <= 992 && $('.e-services-filters .filter-boxes').length > 0) {
    $('.e-services-filters .filter-boxes').slick({
      autoplay: false,
      arrows: false,
      infinite: false,
      // slidesToShow: 1,
      swipeToSlide: true,
      variableWidth: true,
      rtl: dir == 'rtl'? true : false,
      ltr: dir == 'ltr'? true : false,
    })
  }
  // end e-services tabs

  // date and time picker
  if ($(".datePicker1").length > 0 || $(".datepicker-reports").length > 0) {
    $(".datePicker1").datepicker({
      todayHighlight: true,
      startDate: new Date()
    });
  
    $(".datepicker-reports").datepicker(); // for reports page
  }
  // date and time picker

  // upload attachments
  $(".popup-content").on('drop', function (ev) {
    // Dropping files
    ev.preventDefault();
    ev.stopPropagation();
    // Clear previous messages
    $(".popup-content .file-uploaded").empty();
    if (ev.originalEvent.dataTransfer) {
      if (ev.originalEvent.dataTransfer.files.length) {
        var droppedFiles = ev.originalEvent.dataTransfer.files;
        for (var i = 0; i < droppedFiles.length; i++) {
          console.log(droppedFiles[i]);
          $(".popup-content .file-uploaded").addClass('active').append(droppedFiles[i].name);
          $(".popup-content .file-uploaded-complaints").addClass('active').append(droppedFiles[i].name);
          // Upload droppedFiles[i] to server
        }
      }
    }
    return false;
  });

  $(".popup-content").on('dragover', function (ev) {
    ev.preventDefault();
  });
  // upload attachments

  // footer nav active state
  $('.dhareeba-footer .footer-nav ul li a').on('click', function () {
    $('.dhareeba-footer .footer-nav ul li a').removeClass('active');
    $(this).addClass('active');
  })
  // footer nav active state

  // start footer stars
  // var selectedRate = 6;
  // $(".star").hover(function () {
  //   $(".star").removeClass("active");
  //   $(this).addClass("active");
  //   $(this).prevAll().addClass("active");
  //   $(this).nextAll().removeClass("active");
  // });
  // $(".star").mouseout(function () {
  //   $(".star").removeClass("active");
  //   var parent = $('.review-stars');
  //   var element = parent.children().get(selectedRate);
  //   var jQueryObject = $(element);
  //   jQueryObject.addClass("active");
  //   jQueryObject.prevAll().addClass("active");
  // });
  // $(".star").click(function () {
  //   selectedRate = $(".review-stars").children().index($(this));
  //   $(this).addClass("active");
  //   $(this).prevAll().addClass("active");
  //   $(this).nextAll().removeClass("active");
  //   $('.review-bar .add-comment-box').toggleClass('active');
  //   $('.review-bar .content').toggleClass('hidden');
  // });
  // end footer stars

  // Footer add comment
  // $('.review-bar .add-comment').on('click', function () {
  //   $('.review-bar .add-comment-box').toggleClass('active');
  //   $('.review-bar .content').toggleClass('hidden');
  // })
  // $('.add-comment-box .cancel-btn').on('click', function () {
  //   $('.review-bar .add-comment-box').toggleClass('active');
  //   $('.review-bar .content').toggleClass('hidden');
  // })
  // Footer add comment

  // add comment submit popup
  $('.add-comment-popup .close-icon').on('click', function () {
    $('.overlay, .add-comment-popup').removeClass('active');
    $('.review-bar .add-comment-box').removeClass('active');
    $('.review-bar .content').removeClass('hidden');
  })
  // add comment submit popup

  // go up
  $('.go-up').on("click", function () {
    $('html, body').animate({ scrollTop: 0 }, 100);
    return false;
  });
  // go up

  // header nav
  if ($(window).width() > 992 && $('.dhareeba-header').length) {
    $('.expandable').on('click', function (e) {
      e.preventDefault();
    });
    $(document).on('scroll', function () {
      var y = $(this).scrollTop();
      var x = $('nav').position();
      if (y > x.top + 150) {
        $('nav').addClass('sticky');
      } else {
        $('nav').removeClass('sticky');
      };
    });
  }
  // header nav

  // welcome popup
  // $('.welcome-popup .welcome-body-screen .close-btn').on('click', function () {
  //   $('.welcome-popup').css('display', 'none');
  //   $('.overlay').removeClass('active');
  // })
  // welcome popup

  // footer call-bar
  // $('.call-numbers-bar .left').on('click', function () {
  //   $('.call-numbers-bar .left, .call-numbers-bar .right').addClass('hidden');
  //   $('.call-numbers-bar .full-subscribe-bar').addClass('active');
  // })
  // $('.call-numbers-bar .close-btn').on('click', function () {
  //   $('.call-numbers-bar .left, .call-numbers-bar .right').removeClass('hidden');
  //   $('.call-numbers-bar .full-subscribe-bar').removeClass('active');
  // })
  // footer call-bar

  // video popup
  $(document).on('click', '.main-news-detailed-content .img-and-desc .news-img.video-play, .video-box .video-img', function () {
    var videoLink = $(this).attr("data-video-src");
    $(".video-area video source").attr('src', videoLink);
    $('.video-popup, .overlay').addClass('active');
    $('video')[0].load();
    $('video')[0].play();
    stopScroll();
  })
  $('.video-popup .video-close').on('click', function () {
    $('.video-popup, .overlay').removeClass('active');
    $('video')[0].pause();
    normalScroll();
  })
  // video popup

  // show more content (rights page)
  $('.view-more-bar').on('click', function () {
    $(this).remove();
    $('.more-content').removeClass('hidden');
  })
  // show more content (rights page)

  // upload popup
  // const appDialog = document.querySelector('dialog.file-upload-popup');
  // const dialogCloseBtn = document.querySelector('.file-upload-popup .close-btn');
  // const openUploadBtn = document.querySelector('#file-upload .upload-text');

  // $('.upload-attachments-field').on('click', function () {
  //   appDialog.showModal();
  //   openUploadBtn.ariaExpanded = 'true';
  //   appDialog.ariaHidden = 'false';
  //   stopScroll();
  // })

  // dialogCloseBtn.addEventListener('click', function() {
  //   appDialog.close();
  //   openUploadBtn.ariaExpanded = 'false';
  //   appDialog.ariaHidden = 'true';
  //   normalScroll();
  // })
  $('.upload-attachments-field').on('click', function () {
    $('.file-upload-popup, .overlay').addClass('active');
    stopScroll();
  })
  $('.file-upload-popup .close-btn').on('click', function (e) {
    e.preventDefault();
    $('.file-upload-popup, .overlay').removeClass('active');
    normalScroll();
  })
  // upload popup

  // International page read more text 
  var maxLength = 300;
  $(".box-international .box-inter .sub-inter").each(function () {
    var myStr = $(this).text();
    if ($.trim(myStr).length > maxLength) {
      var newStr = myStr.substring(0, maxLength);
      var removedStr = myStr.substring(maxLength, $.trim(myStr).length);
      $(this).empty().html(newStr);
      if (dir == 'ltr') {
        $(this).append('<a href="javascript:void(0);" class="read-more">...read more</a>');
      } else {
        $(this).append('<a href="javascript:void(0);" class="read-more">...عرض المزيد</a>');
      }
      $(this).append('<span class="more-text">' + removedStr + '</span>');
    }
  });
  $(".read-more").click(function () {
    $(this).siblings(".more-text").contents().unwrap();
    $(this).remove();
  });
  // International page read more text

  // like / dislike btns
  // $('.thumbs-rate .thumbs-up').on('click', function () {
  //   $(this).addClass('active');
  //   $(this).siblings('.thumbs-down').addClass('inactive');
  // })
  // $('.thumbs-rate .thumbs-down').on('click', function () {
  //   $(this).addClass('active');
  //   $(this).siblings('.thumbs-up').addClass('inactive');
  // })
  // like / dislike btns

  // reports page select change
  $('.report-body-screen .informer-select').on('change', function () {
    var targetGroup = $(this).val();
    $('.informer[targetgroup]').addClass('hidden');
    $('.informer[targetgroup=' + targetGroup + ']').removeClass('hidden');
  })

  $('.report-body-screen .party-select').on('change', function () {
    var targetGroup = $(this).val();
    $('.reported-party[targetgroup]').addClass('hidden');
    $('.reported-party[targetgroup=' + targetGroup + ']').removeClass('hidden');
  })

  $('.report-body-screen .brokers-select').on('change', function () {
    var targetGroup = $(this).val();
    $('.brokers[targetgroup]').addClass('hidden');
    $('.brokers[targetgroup=' + targetGroup + ']').removeClass('hidden');
  })

  $('.report-body-screen .structure-select').on('change', function () {
    var targetGroup = $(this).val();
    $('.structure[targetgroup]').addClass('hidden');
    $('.structure[targetgroup=' + targetGroup + ']').removeClass('hidden');
  })
  // reports page select change

  // service page steps text popup
  $('.service-steps-section .steps-content .steps-box.expand').on('click', function () {
    $('.stepText-popup').fadeIn(200);
    var stepText = $(this).find('.step-desc').html();
    $('.stepText-popup p').html(stepText);
  })

  $('.stepText-popup .close-btn').on('click', function () {
    $('.stepText-popup').fadeOut(200);
  })
  // service page steps text popup

  // make homepage banner full width if text is empty
  if ($('.homepage-head .text-and-img .head-text p:first-child').text() == '' && $('.homepage-head .text-and-img .head-text .head-more-btn a').text() == '') {
    // console.log($('.text-and-img .head-text p:first-child').text());
    $('.homepage-head .text-and-img .head-text').hide();
    $('.homepage-head .text-and-img .head-img').addClass('full');
  }
  // make homepage banner full width if text is empty
  if ($('dialog.app-dialog').length > 0) {
    if ($(window).width() > 991) {
      const appDialog = document.querySelector('dialog.app-dialog');
      const dialogCloseBtn = document.querySelector('.app-dialog .close-btn');
      $('.apps-icons .app-link').on('click', function(e) {
          e.preventDefault();
          var appTitle = $(this).find('.app-text').text().trim();
          var appImg = $(this).find('.app-icon img').attr('src');
          
          var gLink = $(this).data('g_url');
          var aLink = $(this).data('a_url');
  
          $('.store-link.app_store').attr('href', aLink);
          $('.store-link.google_play').attr('href', gLink);
          $('.app-dialog .app-icon img').attr('src', appImg);
          $('.app-dialog .app-icon img').attr('alt', appTitle);
          $('.app-dialog h4').text(appTitle);
          appDialog.showModal();
          appDialog.ariaHidden = 'false';
      })
  
      dialogCloseBtn.addEventListener('click', function() {
        appDialog.close();
        appDialog.ariaHidden = 'true';
      })
    } else {
      var userAgent = navigator.userAgent || navigator.vendor || window.opera;
      // console.log(userAgent);
      // Windows Phone must come first because its UA also contains "Android"
      if (/windows phone/i.test(userAgent)) {
        $('.apps-icons .app-link').each(function() {
          var gLink = $(this).data('g_url');
          $(this).attr('href', gLink);
        })
        return "Windows Phone";
      }
  
      if (/android/i.test(userAgent)) {
        $('.apps-icons .app-link').each(function() {
          var gLink = $(this).data('g_url');
          $(this).attr('href', gLink);
        })
        return "Android";
      }
  
      // iOS detection from: http://stackoverflow.com/a/9039885/177710
      if (/iPad|iPhone|iPod|Macintosh/.test(userAgent) && !window.MSStream) {
        $('.apps-icons .app-link').each(function() {
          var gLink = $(this).data('a_url');
          $(this).attr('href', gLink);
        })
        return "iOS";
      }
  
      return "unknown";
    }
  }

  // mobile input
  if ($('.phone-numbers').length) {
    $('.phone-numbers').removeAttr('maxlength');
    $('.phone-numbers').removeAttr('onkeypress');
    
    $('.phone-numbers').intlTelInput({
      initialCountry: "qa",
      utilsScript: "/assets/src/vendors/js/intlTelInput/utils.js",
      autoPlaceholder: "aggressive",
      customPlaceholder: function(selectedCountryPlaceholder, selectedCountryData) {
        var number = selectedCountryPlaceholder.replace(/[()\-\s]/g, '');
        return number;
      },
    })
  }
  // phone number input to arabic
  // if (dir === 'rtl') {
  //   var arabicNumerals = ['٠', '١', '٢', '٣', '٤', '٥', '٦', '٧', '٨', '٩'];
  //   $('.phone-numbers').on('input', function () {
  //     var englishNumber = $(this).val();
  //     var arabicNumber = '';
  //     for (var i = 0; i < englishNumber.length; i++) {
  //       var englishDigit = englishNumber.charAt(i);
  //       var arabicDigit = arabicNumerals[englishDigit] || englishDigit;
  //       arabicNumber += arabicDigit;
  //     }
  //     $(this).val(arabicNumber);
  //   });
  //   function toEnglishNumber(strNum) {
  //     var ar = '٠١٢٣٤٥٦٧٨٩'.split('');
  //     var en = '0123456789'.split('');
  //     strNum = strNum.replace(/[٠١٢٣٤٥٦٧٨٩]/g, x => en[ar.indexOf(x)]);
  //     strNum = strNum.replace(/[^\d]/g, '');
  //     return strNum;
  //   }
  //   $(document).on('keyup', '.phone-numbers', function () {
  //     var engNumbers = toEnglishNumber($(this).val());
  //     $(this).attr('data-number', engNumbers);
  //   });
  // } else {
  // }
  $(document).on('keyup', '.phone-numbers', function () {
    var engNumbers = $(this).val();
    $(this).attr('data-number', engNumbers);
  });
  // phone number input to arabic

  $('.phone-numbers').on('input', function (e) { //  Allow only numbers
    var inputValue = $(this).val();
    $(this).val(inputValue.replace(/[^\d\u0660-\u0669\u06F0-\u06F9]/g, ''));
  });
  // mobile input

  // $('form:not(.input-fields) input').each(function() { // Adding hidden labels to all form's inputs
  //   $(this).parent().prepend(`<label class="visually-hidden" for="${$(this).attr('id')}">${$(this).attr('placeholder')}</label>`)
  // })
  // $('form:not(.input-fields) select').each(function() { // Adding hidden labels to all form's select
  //   $(this).parent().prepend(`<label class="visually-hidden" for="${$(this).attr('id')}">${$(this).children()[0].label}</label>`)
  // })
  if ($('.datePicker1').length > 0 || $('.datepicker-reports').length > 0) {
    if (dir == 'ltr') {
      $('.datePicker1, .datepicker-reports').parent().find('label').append('<span class="visually-hidden">Enter date in the format MM/DD/YYYY</span>');
    } else {
      $('.datePicker1, .datepicker-reports').parent().find('label').append('<span class="visually-hidden">أدخل التاريخ بالتنسيق MM/DD/YYYY</span>');
    }
  }
  
  // form validation
  
  if ($('.report-body-screen').length > 0) {
    $.validator.addMethod("emailPattern", function (value, element) {
      return this.optional(element) || /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/.test(value);
    });
    $.validator.addMethod("intlTelNumber", function(value, element) {
      return this.optional(element) || $(element).intlTelInput("isValidNumber");
    });
    $('form').each(function () {
      $(this).validate({
        rules: {
          email: {
            required: true,
            emailPattern: true
          },
          "e-mail": {
            required: true,
            emailPattern: true
          },
          number: {
            required: true,
            intlTelNumber: true
          },
        },
        errorElement: 'em',
      });
    })
  }
  if ($('.tax-calc-2-body-screen .income-tax').length > 0) {
    $.validator.addMethod("percentage", function (value, element) {
      return this.optional(element) || /^(?!100(\.0+)?$)(\d{1,2}(\.\d+)?)$/.test(value);
    });
    $('form.input-fields').validate({
      rules: {
        percentage: {
          percentage: true
        }
      },
      messages: {
        percentage: {
          percentage: dir == 'ltr'? 'Please provide percentage under 100' : 'يرجى تقديم نسبة أقل من 100'
        }
      },
      errorElement: 'em',
    })
  }
  // form validation

  // Stataistic page
  // !(function (e, t) {
  //   "object" == typeof exports && "object" == typeof module
  //     ? (module.exports = t())
  //     : "function" == typeof define && define.amd
  //       ? define([], t)
  //       : "object" == typeof exports
  //         ? (exports.counterUp = t())
  //         : (e.counterUp = t());
  // })(self, function () {
  //   return (() => {
  //     "use strict";
  //     var e = {
  //       d: (t, n) => {
  //         for (var o in n)
  //           e.o(n, o) &&
  //             !e.o(t, o) &&
  //             Object.defineProperty(t, o, { enumerable: !0, get: n[o] });
  //       },
  //       o: (e, t) => Object.prototype.hasOwnProperty.call(e, t),
  //       r: (e) => {
  //         "undefined" != typeof Symbol &&
  //           Symbol.toStringTag &&
  //           Object.defineProperty(e, Symbol.toStringTag, {
  //             value: "Module",
  //           }),
  //           Object.defineProperty(e, "__esModule", { value: !0 });
  //       },
  //     },
  //       t = {};
  //     e.r(t), e.d(t, { default: () => n, divideNumbers: () => r });
  //     const n = (e, t = {}) => {
  //       const {
  //         action: n = "start",
  //         duration: i = 1e3,
  //         delay: u = 16,
  //       } = t;
  //       if ("stop" === n) return void o(e);
  //       if ((o(e), !/[0-9]/.test(e.innerHTML))) return;
  //       const l = r(e.innerHTML, {
  //         duration: i || e.getAttribute("data-duration"),
  //         delay: u || e.getAttribute("data-delay"),
  //       });
  //       (e._countUpOrigInnerHTML = e.innerHTML),
  //         (e.innerHTML = l[0] || "&nbsp;"),
  //         (e.style.visibility = "visible");
  //       const c = function () {
  //         (e.innerHTML = l.shift() || "&nbsp;"),
  //           l.length
  //             ? (clearTimeout(e.countUpTimeout),
  //               (e.countUpTimeout = setTimeout(c, u)))
  //             : (e._countUpOrigInnerHTML = void 0);
  //       };
  //       e.countUpTimeout = setTimeout(c, u);
  //     },
  //       o = (e) => {
  //         clearTimeout(e.countUpTimeout),
  //           e._countUpOrigInnerHTML &&
  //           ((e.innerHTML = e._countUpOrigInnerHTML),
  //             (e._countUpOrigInnerHTML = void 0)),
  //           (e.style.visibility = "");
  //       },
  //       r = (e, t = {}) => {
  //         const { duration: n = 1e3, delay: o = 16 } = t,
  //           r = n / o,
  //           i = e.toString().split(/(<[^>]+>|[0-9.][,.0-9]*[0-9]*)/),
  //           u = [];
  //         for (let e = 0; e < r; e++) u.push("");
  //         for (let e = 0; e < i.length; e++)
  //           if (
  //             /([0-9.][,.0-9]*[0-9]*)/.test(i[e]) &&
  //             !/<[^>]+>/.test(i[e])
  //           ) {
  //             let t = i[e];
  //             const n = [...t.matchAll(/[.,]/g)]
  //               .map((e) => ({ char: e[0], i: t.length - e.index - 1 }))
  //               .sort((e, t) => e.i - t.i);
  //             t = t.replace(/[.,]/g, "");
  //             let o = u.length - 1;
  //             for (let e = r; e >= 1; e--) {
  //               let i = parseInt((t / r) * e, 10);
  //               (i = n.reduce(
  //                 (e, { char: t, i: n }) =>
  //                   e.length <= n ? e : e.slice(0, -n) + t + e.slice(-n),
  //                 i.toString()
  //               )),
  //                 (u[o--] += i);
  //             }
  //           } else for (let t = 0; t < r; t++) u[t] += i[e];
  //         return (u[u.length] = e.toString()), u;
  //       };
  //     return t;
  //   })();
  // });
  // $(function () {
  //   /* START :: Counter Up */
  //   if ($(".counter, .tax-statistics").length) {
  //     const counterUp = window.counterUp.default;
  //     const callback = (entries) => {
  //       entries.forEach((entry) => {
  //         const el = entry.target;
  //         if (
  //           entry.isIntersecting &&
  //           !el.classList.contains("is-visible")
  //         ) {
  //           counterUp(el, {
  //             duration: 1000,
  //             delay: 16,
  //           });
  //           el.classList.add("is-visible");
  //         }
  //       });
  //     };
  //     const IO = new IntersectionObserver(callback, { threshold: 1 });
  //     $(".counter, .tax-statistics .number").each(function () {
  //       IO.observe(this);
  //     });
  //   }
  //   /* END :: Counter Up */
  // });
  //End Stataistic page
});
