$(function () {
  'use strict';
  if ($('.tax-calc-2-body-screen').length > 0) {
    const dir = $('html').attr('dir');

    // Clear validation messages
    $.fn.clearValidation = function(){var v = $(this).validate();$('[name]',this).each(function(){v.successList.push(this);v.showErrors();});v.resetForm();v.reset();};
    // Clear validation messages

    // tax calc percentages API
    const taxUrl = $('#tax-content').attr('json-page');
    $.ajax({
      // url: './assets/apis/Tax-Calculator.json',
      url: taxUrl,
      type: "get",
      success: function (data) {
        data = $.parseJSON(data);
        var res = data.root.assets.document.content.root;
        var goodsType = res["goods-type-container"]["goods-type-tax"];
        var withholdingService = res["withholding-tax-container"]["withholding-tax"];
        var incomeTaxRate = res["income-tax-container"]["income-tax"];
        var capitalTaxRate = res["capital-gains-container"]["capital-gains"];
        // console.log(res);

        if ($('.tax-calc-2-body-screen .capital-gains').length) { // res["capital-gains-container"]["capital-gains"][i].percentage
          for (let i = 0; i < capitalTaxRate.length; i++) {
            $('.capital-gains .form-select').append(`
              <option value="${res["capital-gains-container"]["capital-gains"][i].percentage}">${res["capital-gains-container"]["capital-gains"][i].percentage}%</option>
            `)
          }
        }

        if ($('.tax-calc-2-body-screen .income-tax').length) {
          for (let i = 0; i < incomeTaxRate.length; i++) {
            let rate = res["income-tax-container"]["income-tax"][i].percentage;
            let other = typeof rate != 'number' && dir == 'rtl' ? 'أخرى' : 'Other';
            $('.income-tax .form-select.tax-rate').append(`
              <option value="${rate}">
                ${typeof rate === 'number' ? rate + '%' : other}
              </option>
            `)
          }
        }

        if ($('.tax-calc-2-body-screen .Withholding-tax').length) {
          for (let i = 0; i < withholdingService.length; i++) {
            $('.Withholding-tax .service-type').append(`
              <option value="${res["withholding-tax-container"]["withholding-tax"][i].percentage}">
                ${res["withholding-tax-container"]["withholding-tax"][i]["service-type"]}
              </option>
            `)
          }
        }

        if ($('.tax-calc-2-body-screen .selective-tax').length) {
          for (let i = 0; i < goodsType.length; i++) {
            $('.selective-tax .form-select').append(`
              <option value="${res["goods-type-container"]["goods-type-tax"][i].percentage}">${res["goods-type-container"]["goods-type-tax"][i]["goods-type"]}</option>
            `)
          }
        }

        if ($('.tax-calc-2-body-screen .value-added-tax').length) {
          $('.value-added-tax #vat-rate').append(`
            <option value="${res["vat-consumer"]["consumer-imports-container"]["consumer-imports"].percentage}">${res["vat-consumer"]["consumer-imports-container"]["consumer-imports"].percentage}%</option>
          `)
        }
      }
    });
    // tax calc percentages API

    // tax calc radio func.
    $('.tax-calc-2-body-screen .radio-area .radio-btn.included-tax').on('click', function () {
      $('.tax-calc-2-body-screen .radio-area .radio-btn').removeClass('active');
      $(this).addClass('active');
      $('.subject-net').addClass('hidden');
      $('.before-tax-input').addClass('hidden');
      $('.retail-price').removeClass('hidden');
      $('.included-tax-input').removeClass('hidden');
    })
    $('.tax-calc-2-body-screen .radio-area .radio-btn.before-tax').on('click', function () {
      $('.tax-calc-2-body-screen .radio-area .radio-btn').removeClass('active');
      $(this).addClass('active');
      $('.subject-net').removeClass('hidden');
      $('.retail-price').addClass('hidden');
      $('.before-tax-input').removeClass('hidden');
      $('.included-tax-input').addClass('hidden');
    })
    // tax calc radio func.

    // tax calc math
    if ($('.tax-calc-2-body-screen').length)
      $('.tax-calc-2-body-screen .input-fields').validate();

    $('.Withholding-tax .input-fields .form-select').on('change', function () { // Withholding tax
      $('input').val('');
      var selectedOption = $(this).val();
      $('.form-group .percentage').val(selectedOption);
    })
    $('.Withholding-tax .calculate').on('click', function (e) {
      e.preventDefault();
      if ($('.Withholding-tax .input-fields').valid()) {
        $('.tax-due-field').addClass('active');
        var taxResult = $('#subject-net').val() * $('.Withholding-tax .input-fields .form-select').val() / 100;
        $('.tax-due-field .tax-result .number').text(taxResult.toLocaleString("en-US", {
          // // minimumFractionDigits: 4,
          maximumFractionDigits: 4,
        }));
      }
    })

    $('.income-tax .taxpayer-type').on('change', function () { // income tax
      $("form.input-fields").clearValidation();
      var selectedPayer = $(this).val();
      if (selectedPayer == 'qatari') {
        $('input').val('');
        $('input, button, .tax-rate').attr('disabled', 'disabled');
        $('.exempt-box').addClass('active');
        $('.income-tax .calculate').addClass('disabled');
      } else if (selectedPayer == 'insert') {
        $('.exempt-box').removeClass('active');
        $('input, button, .tax-rate').removeAttr('disabled');
        $('#percentage').val('');
        $('.income-tax .calculate').removeClass('disabled');
      } else if (selectedPayer == 'gcc-national-resident-in-qatar-') {
        var switch_lang = $("html").attr("lang");
        if (switch_lang == 'en') {
          $('.lang-btn').click();
        }
        $('input').val('');
        $('input, button, .tax-rate').attr('disabled', 'disabled');
        $('.exempt-box').addClass('active');
        $('.income-tax .calculate').addClass('disabled');
      } else {
        $('input, button, .tax-rate').removeAttr('disabled');
        $('.exempt-box').removeClass('active');
        $('#percentage').val(selectedPayer);
        $('#percentage').attr('disabled', 'disabled');
        $('.income-tax .calculate').removeClass('disabled');
      }

      $('.income-tax .reset-view').remove();
      $('.income-tax .tax-rate').removeClass('hidden');
      $('.income-tax .tax-rate-input').addClass('hidden');
      $('.income-tax .tax-rate').val('')
    })
    $('.income-tax .tax-rate').on('change', function () {
      var taxRate = $(this).val();
      if (taxRate.toLowerCase() == 'other') {
        $('#rate-error').hide();
        $('#tax-rate-input-error').show();
        $('.income-tax .tax-rate').addClass('hidden');
        $('.income-tax .tax-rate-input').removeClass('hidden').attr({ required: true, name: 'number2' });
        if (dir == 'ltr') {
          $('.income-tax .tax-rate-input').parent().append('<div class="reset-view">choose again</div>');
        } else {
          $('.income-tax .tax-rate-input').parent().append('<div class="reset-view">اختر مرة أخرى</div>');
        }
      } else {
        $('#tax-rate-input-error').hide();
        $('#rate-error').show();
      }
    })
    $('body').on('click', '.income-tax .reset-view', function () {
      $('.income-tax .tax-rate').val('')
      $('.income-tax .tax-rate').removeClass('hidden');
      $('.income-tax .tax-rate-input').addClass('hidden');
      $('.income-tax .reset-view').remove();
    })
    $('.income-tax .calculate').on('click', function (e) {
      e.preventDefault();
      if ($('.input-fields').valid()) {
        $('.tax-due-field').addClass('active');
        if ($('.income-tax .tax-rate').hasClass('hidden')) {
          var taxResult = $('#subject-net').val() * $('#percentage').val() / 100 * $('.income-tax .tax-rate-input').val() / 100;
        } else {
          var taxResult = $('#subject-net').val() * $('#percentage').val() / 100 * $('.income-tax .tax-rate').val() / 100;
        }
        $('.tax-due-field .tax-result .number').text(taxResult.toLocaleString("en-US", {
          // minimumFractionDigits: 4,
          maximumFractionDigits: 4,
        }));
      }
    })

    $('.capital-gains .input-1, .capital-gains .input-2').keyup(function () {
      var resultInput = $('.capital-gains .input-fields .input-1').val() - $('.capital-gains .input-fields .input-2').val();
      $('.result-input').val(resultInput);
    })
    $('.capital-gains .calculate').on('click', function (e) { // capital gains tax
      e.preventDefault();
      if ($('.input-fields').valid()) {
        $('.tax-due-field').addClass('active');
        var taxResult = $('.result-input').val() * $('.form-select').val() / 100;
        $('.tax-due-field .tax-result .number').text(taxResult.toLocaleString("en-US", {
          // minimumFractionDigits: 4,
          maximumFractionDigits: 4,
        }));
      }
    })

    $('.selective-tax .form-select').on('change', function () { // selective tax
      $('input').val('');
      $('.tax-calc-2-body-screen .radio-area .radio-btn').removeClass('active');
      $('.radio-btn.before-tax').addClass('active');
      $('.subject-net').removeClass('hidden');
      $('.retail-price').addClass('hidden');
      $('.before-tax-input').removeClass('hidden');
      $('.included-tax-input').addClass('hidden');
      var selectiveType = $(this).val();
      $('#percentage').val(selectiveType);
    })
    $('.selective-tax #standard_price').keyup(function () {
      let inputVal
      if ($('.before-tax-input #retail').val() == '') {
        inputVal = $(this).val();
      } else {
        if (Number($(this).val()) > Number($('.before-tax-input #retail').val())) {
          inputVal = $(this).val();
        } else {
          inputVal = $('.before-tax-input #retail').val();
        }
      }

      var resultInput = inputVal * $('#percentage').val() / 100;
      resultInput.toFixed(4);
      $('#subject-net').val(resultInput);
    })
    $('.selective-tax .before-tax-input #retail').keyup(function () {
      let inputVal
      if ($('#standard_price').val() == '') {
        inputVal = $(this).val();
        // console.log(inputVal);
      } else {
        if (Number($(this).val()) > Number($('#standard_price').val())) {
          inputVal = $(this).val();
          // console.log(inputVal);
        } else {
          inputVal = $('#standard_price').val();
          // console.log(inputVal);
        }
      }

      var resultInput = inputVal * $('#percentage').val() / 100;
      $('#subject-net').val(resultInput);
    })
    $('.selective-tax .included-tax-input #retail_included').keyup(function () {
      var perscentageVal = $('#percentage').val();
      if (perscentageVal == 100) {
        var resultInput = $('.selective-tax .included-tax-input #retail_included').val() * 50 / 100;
        $('#retail-price').val(parseInt(resultInput));
        // console.log('100');
      } else if (perscentageVal == 50) {
        var resultInput = $('.selective-tax .included-tax-input #retail_included').val() * 2 / 3;
        $('#retail-price').val(parseInt(resultInput));
        // console.log('50');
      } else if (perscentageVal == 25) {
        var resultInput = $('.selective-tax .included-tax-input #retail_included').val() * 80 / 100;
        $('#retail-price').val(parseInt(resultInput));
        // console.log('25');
      }
    })
    $('.selective-tax .calculate').on('click', function (e) {
      e.preventDefault();
      if ($('.input-fields').valid()) {
        $('.tax-due-field').addClass('active');
        if ($('.radio-btn.before-tax').hasClass('active')) {
          var taxResult = Number($('.before-tax-input #retail').val()) + Number($('#subject-net').val());
          $('.tax-due-field .field-text').addClass('hidden');
          $('.tax-due-field .result-before-tax').removeClass('hidden');
          $('.tax-due-field .tax-result .number').text(taxResult.toLocaleString("en-US", {
            // minimumFractionDigits: 4,
            maximumFractionDigits: 4,
          }));
        } else {
          var biggerPrice;
          if (Number($('#retail-price').val()) > Number($('#standard_price').val())) {
            biggerPrice = Number($('#retail-price').val())
          } else {
            biggerPrice = Number($('#standard_price').val())
          }

          var taxResult = biggerPrice * Number($('#percentage').val()) / 100;
          $('.tax-due-field .field-text').addClass('hidden');
          $('.tax-due-field .result-after-tax').removeClass('hidden');
          $('.tax-due-field .tax-result .number').text(taxResult.toLocaleString("en-US", {
            // minimumFractionDigits: 4,
            maximumFractionDigits: 4,
          }));
        }
      }
    })
    $('.selective-tax .radio-btn').on('click', function () {
      $('#retail, #retail_included, #subject-net, #retail-price').val('')
    })
    $('.radio-btn.before-tax').on('click', function () {
      $('.selective-tax .included-tax-input #retail_included').removeAttr('required')
      $('.before-tax-input #retail').attr('required', 'required')
    })
    $('.radio-btn.included-tax').on('click', function () {
      $('.before-tax-input #retail').removeAttr('required')
      $('.included-tax-input #retail_included').attr('required', 'required')
    })

    $('.value-added-tax .target-group').on('change', function () { // value added tax
      var targetGroup = $(this).val();
      $('div[data-targetGroup]').removeClass('show');
      $('div[data-targetGroup=' + targetGroup + ']').addClass('show');
      if ($('div[data-targetGroup="business"]').hasClass('show')) {
        $('.value-added-tax .during-fields').removeClass('show');
        $('.value-added-tax .specific-fields').addClass('show');
        $('.business-type').val('specific');
      }

      // for view reset ---->
      $('input').val('');
      $('.value-added-tax .transaction-type').val('import');
      $('.value-added-tax .import[data-targetgroup="consumer"]').addClass('active');
      $('.value-added-tax .local[data-targetgroup="consumer"]').removeClass('active');
      $('.value-added-tax .radio-after').addClass('hidden');
      $('.value-added-tax .radio-before').removeClass('hidden');
      $('.radio-btn.included-tax').removeClass('active');
      $('.radio-btn.before-tax').addClass('active');
      // <---- for view reset
    })
    $('.value-added-tax .transaction-type').on('change', function () { // value added tax
      var targetGroup = $(this).val();
      if ('import' == targetGroup) {
        $('.import[data-targetgroup="consumer"]').addClass('active');
        $('.local[data-targetgroup="consumer"]').removeClass('active');
      } else {
        $('.import[data-targetgroup="consumer"]').removeClass('active');
        $('.local[data-targetgroup="consumer"]').addClass('active');
      }

      // for view reset ---->
      $('input').val('');
      $('.value-added-tax .radio-after').addClass('hidden');
      $('.value-added-tax .radio-before').removeClass('hidden');
      $('.radio-btn.included-tax').removeClass('active');
      $('.radio-btn.before-tax').addClass('active');
      // <---- for view reset
    })
    $('.value-added-tax .radio-area .radio-btn.included-tax').on('click', function () {
      $('.radio-after').removeClass('hidden');
      $('.radio-before').addClass('hidden');
    })
    $('.value-added-tax .radio-area .radio-btn.before-tax').on('click', function () {
      $('.radio-after').addClass('hidden');
      $('.radio-before').removeClass('hidden');
    })

    $('.value-added-tax .business-type').on('change', function () {
      var businessType = $(this).val();
      if (businessType == 'specific') {
        $('.value-added-tax .during-fields').removeClass('show')
        $('.value-added-tax .specific-fields').addClass('show')
      } else {
        $('.value-added-tax .specific-fields').removeClass('show');
        $('.value-added-tax .during-fields').addClass('show')
      }

      // for view reset ---->
      $('input').val('');
      $('.value-added-tax .business-transaction').val('import');
      $('.specific-fields.import').addClass('active');
      $('.specific-fields.local').removeClass('active');
      $('.radio-btn.included-tax').removeClass('active');
      $('.radio-btn.before-tax').addClass('active');
      $('.radio-after').addClass('hidden');
      $('.radio-before').removeClass('hidden');
      // <---- for view reset
    })
    $('.value-added-tax .business-transaction').on('change', function () {
      $('input').val('');
      var businessTransaction = $(this).val();
      if (businessTransaction == 'import') {
        $('.specific-fields.import').addClass('active');
        $('.specific-fields.local').removeClass('active');

        // for view reset ---->
        $('.radio-btn.included-tax').removeClass('active');
        $('.radio-btn.before-tax').addClass('active');
        $('.radio-after').addClass('hidden');
        $('.radio-before').removeClass('hidden');
        // <---- for view reset
      } else {
        $('.specific-fields.import').removeClass('active');
        $('.specific-fields.local').addClass('active');
      }
    })
    $('.value-added-tax .radio-before[data-targetgroup="consumer"]').keyup(function () {
      var beforeTaxField = $('.value-added-tax .radio-before[data-targetgroup="consumer"] #buy-before').val() * $('.consumer-rate').val() / 100
      $('.radio-before[data-targetgroup="consumer"] #tax-rate').val(beforeTaxField);
    })
    $('.value-added-tax .radio-after[data-targetgroup="consumer"] #buy-after').keyup(function () {
      var valueInt = Number($('.consumer-rate').val() / 100) + 1;
      var beforeTaxField = $('.value-added-tax .radio-after[data-targetgroup="consumer"] #buy-after').val() / valueInt
      $('.radio-after[data-targetgroup="consumer"] #tax-rate-after').val(parseInt(beforeTaxField));
    })
    $('.value-added-tax .specific-fields.radio-before #local-specific').keyup(function () {
      var valueInt = $('.specific-fields.radio-before #local-specific').val() * Number($('.specific-fields .business-rate').val() / 100);
      $('.specific-fields.radio-before #local-specific-value').val(parseInt(valueInt));
    })
    $('.value-added-tax .specific-fields.radio-after #local-specific-after').keyup(function () {
      var taxEqu = Number($('.specific-fields .business-rate').val() / 100) + 1;
      var valueInt = $('.specific-fields.radio-after #local-specific-after').val() / taxEqu;
      $('.specific-fields.radio-after #local-specific-value-after').val(parseInt(valueInt));
    })
    $('.during-fields #during-before').keyup(function () {
      var valueInt = $('.during-fields #during-before').val() * $('.value-added-rate').val() / 100;
      $('.during-fields #during-value').val(valueInt);
    })
    $('.value-added-tax .calculate').on('click', function (e) {
      e.preventDefault();
      if ($('.input-fields').valid()) {
        $('.tax-due-field').addClass('active');
        if ($('.value-added-tax .transaction-type').val() == 'import' && $('div[data-targetgroup="consumer"]').hasClass('show')) {
          var consumerResult = $('#consumer-import-input').val() * $('.consumer-rate').val() / 100;
          $('.tax-due-field .tax-result .number').text(consumerResult.toLocaleString("en-US", {
            // minimumFractionDigits: 4,
            maximumFractionDigits: 4,
          }));
          $('.field-text').hide()
          $('.field-text.result-1').show()
          // console.log('1');
        } else if ($('.value-added-tax .transaction-type').val() == 'local' && $('.radio-area[data-targetgroup="consumer"] .before-tax.active').length) {
          var consumerResult = Number($('.radio-before[data-targetgroup="consumer"] #buy-before').val()) + Number($('.radio-before[data-targetgroup="consumer"] #tax-rate').val());
          $('.tax-due-field .tax-result .number').text(consumerResult.toLocaleString("en-US", {
            // minimumFractionDigits: 4,
            maximumFractionDigits: 4,
          }));
          $('.field-text').hide()
          $('.field-text.result-2').show()
          // console.log('2');
        } else if ($('.value-added-tax .transaction-type').val() == 'local' && $('.radio-area[data-targetgroup="consumer"] .included-tax.active').length) {
          var valueInt = Number($('.consumer-rate').val() / 100) + 1;
          var beforeTaxField = $('.value-added-tax .radio-after[data-targetgroup="consumer"] #buy-after').val() / valueInt
          var consumerResult = Number($('.consumer-rate').val() / 100) * Number(beforeTaxField);
          $('.tax-due-field .tax-result .number').text(consumerResult.toLocaleString("en-US", {
            // minimumFractionDigits: 4,
            maximumFractionDigits: 4,
          }));
          $('.field-text').hide()
          $('.field-text.result-3').show()
          // console.log('3');
        } else if ($('.business-transaction').val() == 'import' && $('.business-type').val() == 'specific' && $('div[data-targetgroup="business"]').hasClass('show')) {
          var businessResult = $('.specific-fields #import-specific').val() * Number($('.specific-fields .business-rate').val() / 100);
          $('.tax-due-field .tax-result .number').text(businessResult.toLocaleString("en-US", {
            // minimumFractionDigits: 4,
            maximumFractionDigits: 4,
          }));
          $('.field-text').hide()
          $('.field-text.result-4').show()
          // console.log('4');
        } else if ($('.business-transaction').val() == 'local' && $('.business-type').val() == 'specific' && $('div[data-targetgroup="business"]').hasClass('show') && $('.radio-btn.before-tax').hasClass('active')) {
          var valueInt = $('.specific-fields.radio-before #local-specific').val() * Number($('.specific-fields .business-rate').val() / 100);

          var businessResult = Number(valueInt) + Number($('.specific-fields.radio-before #local-specific').val());
          $('.tax-due-field .tax-result .number').text(businessResult.toLocaleString("en-US", {
            // minimumFractionDigits: 4,
            maximumFractionDigits: 4,
          }));
          $('.field-text').hide()
          $('.field-text.result-5').show()
          // console.log('5');
        } else if ($('.business-transaction').val() == 'local' && $('.business-type').val() == 'specific' && $('div[data-targetgroup="business"]').hasClass('show') && $('.radio-btn.included-tax').hasClass('active')) {
          var valueInt = Number($('.specific-fields .business-rate').val() / 100) + 1;
          var beforeTaxField = $('.specific-fields.radio-after #local-specific-after').val() / valueInt;

          var businessResult = Number($('.specific-fields .business-rate').val() / 100) * beforeTaxField;
          $('.tax-due-field .tax-result .number').text(businessResult.toLocaleString("en-US", {
            // minimumFractionDigits: 4,
            maximumFractionDigits: 4,
          }));
          $('.field-text').hide()
          $('.field-text.result-3').show()
          // console.log('6');
        } else if ($('.business-type').val() == 'during' && $('div[data-targetgroup="business"]').hasClass('show')) {
          var valueInt = $('.during-fields #during-before').val() * $('.value-added-rate').val() / 100;

          var businessResult = Number(valueInt) - Number($('#during-on-import').val());
          $('.tax-due-field .tax-result .number').text(businessResult.toLocaleString("en-US", {
            // minimumFractionDigits: 4,
            maximumFractionDigits: 4,
          }));
          $('.field-text').hide()
          $('.field-text.result-6').show()
          // console.log('7');
        }
      }
    })

    $('.tax-calc-2-body-screen .reset-btn').on('click', function () {
      $('.tax-calc-2-body-screen input[type="number"]').val('');
    })
    // tax calc math
  }
})