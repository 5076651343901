$(function () {
  'use strict';


  if ($('.suggestions-body-screen').length > 0) {
    const dir = $('html').attr('dir');
    const lang = $('html').attr('lang');

    $.validator.addMethod("emailPattern", function (value, element) { // new method for email validation
      return this.optional(element) || /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/.test(value);
    });
    $.validator.addMethod("intlTelNumber", function (value, element) {
      return this.optional(element) || $(element).intlTelInput("isValidNumber");
    });

    const messagesError = {
      name: {
        en: 'Please provide your name',
        ar: 'رجاء كتابة اسمك'
      },
      email: {
        en: 'Please enter a valid email address',
        ar: 'رجاء إدخال عنوان بريد إلكتروني صالح'
      },
      phone: {
        required: {
          en: 'Please provide your number without country code',
          ar: "يرجى تقديم رقمك بدون رمز البلد"
        },
        intlTelNumber: {
          en: 'Please enter a valid phone Number',
          ar: 'يرجى إدخال رقم هاتف صحيح'
        }
      },
      id_number: {
        en: 'Please provide your ID number',
        ar: "رجاء إدخال رقم الهوية الخاصة بك"
      },
      tax_id_num: {
        en: 'Please provide tax identification number',
        ar: 'يرجى تقديم رقم التعريف الضريبي'
      },
      address: {
        en: 'Please provide your address',
        ar: 'يرجى تقديم عنوانك'
      },
      topic: {
        en: 'Please provide the topic',
        ar: 'يرجى تقديم الموضوع'
      },
      details: {
        en: 'Please provide the details',
        ar: 'يرجى تقديم التفاصيل'
      },
      docNumber: {
        en: 'Please provide the document number',
        ar: 'يرجى تقديم رقم المستند'
      },
      docType: {
        en: 'Please select document type',
        ar: 'الرجاء تحديد نوع المستند'
      },
      taxNumber: {
        en: 'Please provide your tax number',
        ar: 'يرجى تقديم الرقم الضريبي'
      },
    }

    $('#complaints').validate({
      rules: {
        number: {
          required: true,
          intlTelNumber: true
        },
        email: {
          required: true,
          emailPattern: true
        },
        name: 'required',
        'tax-identification-number': 'required',
        'commercial-registration-no': 'required',
        'the-topic': 'required',
        'address': 'required',
      },
      messages: {
        number: {
          required: dir == 'ltr' ? messagesError.phone.required.en : messagesError.phone.required.ar,
          intlTelNumber: dir == 'ltr' ? messagesError.phone.intlTelNumber.en : messagesError.phone.intlTelNumber.ar
        },
        email: {
          required: dir == 'ltr' ? messagesError.email.en : messagesError.email.ar,
          emailPattern: dir == 'ltr' ? messagesError.email.en : messagesError.email.ar
        },
        name: dir == 'ltr' ? messagesError.name.en : messagesError.name.ar,
        'tax-identification-number': dir == 'ltr' ? messagesError.tax_id_num.en : messagesError.tax_id_num.ar,
        'commercial-registration-no': dir == 'ltr' ? messagesError.id_number.en : messagesError.id_number.ar,
        'the-topic': dir == 'ltr' ? messagesError.topic.en : messagesError.topic.ar,
        'address': dir == 'ltr' ? messagesError.address.en : messagesError.address.ar,
      },
      errorElement: 'em',
    })

    $('#suggestion').validate({
      rules: {
        'phone-to-call': {
          intlTelNumber: true
        },
        "e-mail": {
          emailPattern: true
        },
      },
      messages: {
        'phone-to-call': {
          required: dir == 'ltr' ? messagesError.phone.required.en : messagesError.phone.required.ar,
          intlTelNumber: dir == 'ltr' ? messagesError.phone.intlTelNumber.en : messagesError.phone.intlTelNumber.ar
        },
        "e-mail": {
          required: dir == 'ltr' ? messagesError.email.en : messagesError.email.ar,
          emailPattern: dir == 'ltr' ? messagesError.email.en : messagesError.email.ar
        },
        name: dir == 'ltr' ? messagesError.name.en : messagesError.name.ar,
        'the-topic': dir == 'ltr' ? messagesError.topic.en : messagesError.topic.ar,
        'the-details': dir == 'ltr' ? messagesError.details.en : messagesError.details.ar,
        'tax-number': dir == 'ltr' ? messagesError.taxNumber.en : messagesError.taxNumber.ar,
        'document-type': dir == 'ltr' ? messagesError.docType.en : messagesError.docType.ar,
        'document-number': dir == 'ltr' ? messagesError.docNumber.en : messagesError.docNumber.ar,
      },
      errorElement: 'em',
    })

    $('#suggestion #phone-to-call').attr('disabled', 'disabled')
    $('#suggestion #name').attr('disabled', 'disabled')
    $('#suggestion #e-mail').attr('disabled', 'disabled')

    $('#suggestion #document-number, #suggestion #tax-number, #suggestion #document-type').on('focusout', function() {
      if ($('#suggestion #document-number').val() === '' || $('#suggestion #tax-number').val() === '' || $('#suggestion #document-type').val() === '') {
        $('#suggestion').valid()
        return
      }

      const taxNumber = $('#suggestion #tax-number');
      const docType = $('#suggestion #document-type');
      const docNumber = $('#suggestion #document-number');

      const url = window.location.origin

      $.ajax({
        url: `${url}/${lang}/ajax/validate-user-data`,
        type: "POST",
        async: false,
        data: {
          "tax-number": taxNumber.val(),
          "document-type": docType.val(),
          "document-number": docNumber.val()
        },
        // beforeSend: function() {
        //   $('#suggestion input, #suggestion textarea, #suggestion select').attr('disabled', 'disabled');
        // },
        success: function (data) {
          // $('#suggestion input, #suggestion textarea, #suggestion select').removeAttr('disabled');

          if ($(data).find('userdata data').attr('status') === 'valid') {
            const nameVal = $(data).find('userdata data').attr('fullname');
            const phoneVal = $(data).find('userdata data').attr('mobile');
            const emailVal = $(data).find('userdata data').attr('email');

            $('#suggestion #phone-to-call').val(phoneVal)
            $('#suggestion #name').val(nameVal)
            $('#suggestion #e-mail').val(emailVal)
          } else {
            $('#suggestion #phone-to-call').val('')
            $('#suggestion #name').val('')
            $('#suggestion #e-mail').val('')
            
            $('#suggestion').valid();
          }
          
        },
        error: function (xhr, status, error) {
          // $('#suggestion input, #suggestion textarea, #suggestion select').removeAttr('disabled');
          console.error("Error: " + error);
        }
      });
    })

  }
})